<template>
  <div>
    <IssueComponent></IssueComponent>
  </div>
  </template>

<script>

import IssueComponent from "../components/IssueComponent.vue"

export default {
 
  data() {
    return {
      
    };
  },
  components: { IssueComponent }
};
</script>
<style></style>